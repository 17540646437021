<template>
  <div>
    <section id="gallery-wrapper">
      <Cards class="white-panel" v-for="item in knowData" :key="item.id" @click.native="showChinaPage(item)">
        <img v-lazy="item.img" class="thumb" slot="media" />
        <p slot="title" class="title" @click.stop="showVideo(item.link)">{{ item.title }}</p>
        <p slot="content" class="content">{{ item.content }}</p>
      </Cards>
    </section>
    <LoadMore></LoadMore>
  </div>
</template>

<script>
  import Cards from '@/components/Cards'
  import LoadMore from '@/components/LoadMore'
  export default {
    components: {
      Cards,
      LoadMore,
    },
    data() {
      return {
        // 去碳小课堂知识
        knowData: [
  {
    id: 0,
    img: require('@/assets/images/logo7.jpg'),
    title: '碳中和数据概览',
    link: 'http://8.138.22.57:8095/',
  },
  {
    id: 1,
    img: require('@/assets/images/logo8.png'),
    title: '全国碳足迹',
    link: 'http://www.tanzhonghe66.cn/#/echart',
  },
          {
            id: 2,
            img: require('@/assets/images/logo9.png'),
            title: '碳排放结构、碳交易及相关政策',
            link:"http://8.138.22.57:8091/"

          },
          {
            id: 3,
           img: require('@/assets/images/logo10.png'),
            title: '碳排放网络舆情词云展示',
            link: 'http://8.138.22.57:8091/WordCloud.html'
          },
                      {
            id: 4,
            img: require('@/assets/images/logo11.png'),
            title: '碳排放数据统计大屏 ',
            link: 'http://8.138.22.57:8099/#/ScreenDesign1'
          },
                                {
            id: 5,
            img: require('@/assets/images/logo12.png'),
            title: '数据预测及分析',
            link: 'http://8.138.22.57:8099/#/ScreenDesign3'
          },
                                {
            id: 6,
            img: require('@/assets/images/logo13.png'),
            title: '专家解读 | 《碳排放权交易管理暂行条例》为实现碳达峰碳中和目标提供有力的制度保障',
            link: 'https://www.mee.gov.cn/zcwj/zcjd/202402/t20240205_1065883.shtml'
          },
                                            {
            id: 7,
             img: require('@/assets/images/logo14.png'),
            title: '中共中央 国务院关于全面推进美丽中国建设的意见2',
            link: 'https://www.mee.gov.cn/zcwj/zyygwj/202401/t20240111_1063458.shtml'
          },

        ],
      }
    },
    methods: {
      // 跳转到详细页面
      goDetail(item) {
        this.$router.push({ path: '/detail', query: { data: item } })
      },
//       showChinaPage() {
//       window.location.href = 'http://localhost:63342/QUC-main/DataBrowsing/China/China.html';
// },
  showVideo(link) {
    window.location.href = link;
  },
    },
    mounted() {
      //   这里是引入js文件，
      ;(function ($, window, document, undefined) {
        var pluginName = 'pinterest_grid',
          defaults = {
            padding_x: 10,
            padding_y: 10,
            no_columns: 3,
            margin_bottom: 50,
            single_column_breakpoint: 700,
          },
          columns,
          $article,
          article_width

        function Plugin(element, options) {
          this.element = element
          this.options = $.extend({}, defaults, options)
          this._defaults = defaults
          this._name = pluginName
          this.init()
        }

        Plugin.prototype.init = function () {
          var self = this,
            resize_finish

          $(window).resize(function () {
            clearTimeout(resize_finish)
            resize_finish = setTimeout(function () {
              self.make_layout_change(self)
            }, 11)
          })

          self.make_layout_change(self)

          setTimeout(function () {
            $(window).resize()
          }, 500)
        }

        Plugin.prototype.calculate = function (single_column_mode) {
          var self = this,
            tallest = 0,
            row = 0,
            $container = $(this.element),
            container_width = $container.width()
          $article = $(this.element).children()

          if (single_column_mode === true) {
            article_width = $container.width() - self.options.padding_x
          } else {
            article_width = ($container.width() - self.options.padding_x * self.options.no_columns) / self.options.no_columns
          }

          $article.each(function () {
            $(this).css('width', article_width)
          })

          columns = self.options.no_columns

          $article.each(function (index) {
            var current_column,
              left_out = 0,
              top = 0,
              $this = $(this),
              prevAll = $this.prevAll(),
              tallest = 0

            if (single_column_mode === false) {
              current_column = index % columns
            } else {
              current_column = 0
            }

            for (var t = 0; t < columns; t++) {
              $this.removeClass('c' + t)
            }

            if (index % columns === 0) {
              row++
            }

            $this.addClass('c' + current_column)
            $this.addClass('r' + row)

            prevAll.each(function (index) {
              if ($(this).hasClass('c' + current_column)) {
                top += $(this).outerHeight() + self.options.padding_y
              }
            })

            if (single_column_mode === true) {
              left_out = 0
            } else {
              left_out = (index % columns) * (article_width + self.options.padding_x)
            }

            $this.css({
              left: left_out,
              top: top,
            })
          })

          this.tallest($container)
          $(window).resize()
        }

        Plugin.prototype.tallest = function (_container) {
          var column_heights = [],
            largest = 0

          for (var z = 0; z < columns; z++) {
            var temp_height = 0
            _container.find('.c' + z).each(function () {
              temp_height += $(this).outerHeight()
            })
            column_heights[z] = temp_height
          }

          largest = Math.max.apply(Math, column_heights)
          _container.css('height', largest + (this.options.padding_y + this.options.margin_bottom))
        }

        Plugin.prototype.make_layout_change = function (_self) {
          if ($(window).width() < _self.options.single_column_breakpoint) {
            _self.calculate(true)
          } else {
            _self.calculate(false)
          }
        }

        $.fn[pluginName] = function (options) {
          return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
              $.data(this, 'plugin_' + pluginName, new Plugin(this, options))
            }
          })
        }
      })(jQuery, window, document)
      //  TODO 如何引入pinterest_grid.js文件
      $(function () {
        $('#gallery-wrapper').pinterest_grid({
          no_columns: 4,
          padding_x: 10,
          padding_y: 10,
          margin_bottom: 50,
          single_column_breakpoint: 700,
        })
      })
    },
  }
</script>

<style lang="less" scoped>
  p {
    margin: 6px 0 2px 0;
    width: 100%;
    text-indent: 2em;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; //显示的行数
    -webkit-box-orient: vertical;
  }
  #gallery-wrapper {
    position: relative;
    max-width: 75%;
    width: 75%;
    margin: 10px auto;
  }
  img.thumb {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .white-panel {
    position: absolute;
    background: white;
    border-radius: 0.1rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    padding: 0.2rem;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  .white-panel h1 {
    font-size: 1em;
  }
  .white-panel h1 a {
    color: #a92733;
  }
  .white-panel:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    margin-top: -2px;
  }
</style>
